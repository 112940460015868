const works = [
    {
        "id": -1,
        "title": "Chipt Checkout",
        "tag": "chipt-checkout",
        "link": "https://apps.apple.com/us/app/chipt/id6455428247",
        "description": "A zero-POS, line-less checkout experience for brick-and-mortar retail, with Stripe payments and RF-chipt tech.",
        "blurb": "A zero-POS, line-less checkout experience for brick-and-mortar retail.",
        "details": [
            "When you shop at brick-and-mortar stores checkout lines are far and away the most unpleasent part of the experience. Chipt Checkout wants to do away with the checkout line, making in-person shopping as seamless as e-commerce.",
            "Chipt - coded in React Native - is built on AWS and Google Cloud infrastructure. It uses Stripe Connect to faciliate a three-party payment flow, and RF-chipt technology for item-level tracking and product-to-device communication.",
            "Chipt has been demoed by hundreds of SoHo, NYC shoppers, and is available for private beta testing on the iOS App Store.",
        ],
    },   
    {
        "id": 0,
        "title": "Aditus",
        "tag": "aditus",
        "link": "https://aditus.dev",
        "description": "A text-to-SQL empowered relational data client available for desktop and web, built on the Azure cloud.",
        "blurb": "AI-centric relational database client for data professionals.",
        "details": [
            "Aditus is a SQL client with natural-language-to-SQL features front and center. It is built on the Sequal text-to-SQL engine, and is available for desktop and web.",
            "It was built on Azure cloud infrastructure, with React, Electron, and Node.js. All styling was done with Tailwind CSS (we wouldn't have it any other way!).",
            "You can make Aditus your SQL client today at https://aditus.dev/download. Never write a full SQL query again!"
        ],
    },   
    {
        "id": 1,
        "title": "Financr",
        "tag": "financr",
        "link": "https://financr.co",
        "description": "A semantic search engine for corporate financials, built with MongoDB, Express, React, Node.js.",
        "blurb": "Semantic search engine for corporate financial data.",
        "details": [
            "Financr is a semantic search engine for corporate financial data. Right now, the vast majority of professional financial analysts as well as retail investors find corporate financial data by reading through SEC filings directly. Most of these filing are filled with boilerplate text people aren't interested in. Financr Search allows analysts and retail investors to search through corporate financial data using natural language queries.",
            "It was built with MongoDB, Express, React, Node.js.",
            "Financr was built as a project in informational retrieval.",
            "It is helping to remedy existing pain points in retrieving financial data and is already being used by Wall Street analysts."
        ],
    },    
    {
        "id": 2,
        "title": "Esterify",
        "tag": "esterify",
        "link": "https://esterify.org",
        "description": "A recyling venture for a more sustainable hospitality industry, and more profitable industrial oils supply chains.",
        "blurb": "Recycling used toiletries from hotels and resorts.",
        "details": [
            "Esterify is a venture proposal to recycle used toiletries from hotels and resorts. Every day, upwards of 10 million bars of soap are discarded by hotels and resorts across the world. Esterify would collect these used toiletries, and chemically recyle them, harvesting the value chemicals in soaps, and selling them as an end-use commodity.",
            "We'll be bulding out Esterify in the coming weeks and months. In the meantime, learn more about Esterify at https://esterify.org."
        ],
    },
    {
        "id": 3,
        "title": "Sequal",
        "tag": "sequal",
        "link": "https://sequal.dev",
        "description": "A top-flight text-to-SQL engine built with React, Express, Postgres, on EC2 and RDS infrastructure.",
        "blurb": "A text-to-SQL engine for SQL developers and analysts.",
        "details": [
            "I started building Sequal with a small Stanford team as a spring break project in March 2023.",
            "Since then, Sequal has been used to generate thousands of SQL queries from natural language, used by SQL developers and analysts across the world.",
            "See our work at https://sequal.dev.",
        ],
    },
    {
        "id": 4,
        "title": "Claros",
        "tag": "claros",
        "link": "https://apps.apple.com/us/app/claros-ai/id1667192004",
        "description": "An arbitrage application for sports betting markets built with React Native, and Firebase, with AWS backend infrastructure.",
        "blurb": "An arbitrage application for sports betting markets.",
        "details": [
            "Claros is an arbitrage application for sports betting markets.",
            "It was built with React Native, and Firebase, with AWS backend infrastructure.",
            "Claros was built in collaboration with a small team of Stanford students, and was launched on the App Store in February 2023.",
        ],
    },
];

export default works;