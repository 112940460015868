import terrell_harper_1 from '../../../assets/photos/terrell_harper_1.jpg';
import terrell_harper_2 from '../../../assets/photos/terrell_harper_2.jpg';
import guadalupe_ortega from '../../../assets/photos/guadalupe_ortega.jpg';
import attica_brothers_1 from '../../../assets/photos/attica_brothers_1.jpg';
import attica_brothers_2 from '../../../assets/photos/attica_brothers_2.jpg';
import gothamist_being_asian_1 from '../../../assets/photos/gothamist_being_asian_1.jpg';
import gothamist_being_asian_2 from '../../../assets/photos/gothamist_being_asian_2.jpg';
import kayla_oliveri_1 from '../../../assets/photos/kayla_oliveri_1.jpg';
import kayla_oliveri_2 from '../../../assets/photos/kayla_oliveri_2.jpg';
import rainier_harris_1 from '../../../assets/photos/rainier_harris_1.jpg';
import jami_floyd_1 from '../../../assets/photos/jami_floyd_1.jpg';

const photos = [
    {
        "id": 0,
        "src": terrell_harper_1,
        "alt": "Terrell Harper 1",
        "orientation": "portrait",
        "blurb": "Nothing to be said of any great importance.",     
    },
    {
        "id": 7,
        "src": kayla_oliveri_1,
        "alt": "Kayla Oliveri 1",
        "orientation": "landscape",
        "blurb": "Nothing to be said of any great importance.",
    },
    {
        "id": 2,
        "src": guadalupe_ortega,
        "alt": "Guadalupe Ortega",
        "orientation": "portrait",
        "blurb": "Nothing to be said of any great importance.",
    },
    {
        "id": 3,
        "src": attica_brothers_1,
        "alt": "Attica Brothers",
        "orientation": "portrait",
        "blurb": "Nothing to be said of any great importance.",
    },
    {
        "id": 6,
        "src": gothamist_being_asian_2,
        "alt": "Gothamist Being Asian",
        "orientation": "portrait",
        "blurb": "Nothing to be said of any great importance.",
    },
    {
        "id": 4,
        "src": attica_brothers_2,
        "alt": "Attica Brothers",
        "orientation": "landscape",
        "blurb": "Nothing to be said of any great importance.",
    },
    {
        "id": 1,
        "src": terrell_harper_2,
        "alt": "Terrell Harper 2",
        "orientation": "portrait",
        "blurb": "Nothing to be said of any great importance.",
    },
    
    {
        "id": 5,
        "src": gothamist_being_asian_1,
        "alt": "Gothamist Being Asian",
        "orientation": "portrait",
        "blurb": "Nothing to be said of any great importance.",
    },
    {
        "id": 8,
        "src": kayla_oliveri_2,
        "alt": "Kayla Oliveri 2",
        "orientation": "landscape",
        "blurb": "Nothing to be said of any great importance.",
    },
    {
        "id": 9,
        "src": jami_floyd_1,
        "alt": "Jami Floyd 1",
        "orientation": "landscape",
        "blurb": "Nothing to be said of any great importance.",
    },
    {
        "id": 10,
        "src": rainier_harris_1,
        "alt": "Rainier Harris 1",
        "orientation": "portrait",
        "blurb": "Nothing to be said of any great importance.",
    },
];

export default photos;
